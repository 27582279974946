<template>
  <div class="edit-user">
    <form action="">
      <div class="header d-flex justify-content-between align-items-center">
        <div class="title">
          <h3>Edit User</h3>
        </div>
        <div class="">
          <div class="d-flex align-items-end">
            <button
              @click.prevent="updateUser"
              class="create-button"
              :class="[{ notAllowed: errorBtnSave }]"
            >
              Save
            </button>
          </div>
        </div>
      </div>

      <div class="form-body">
        <div class="row mb-2">
          <div class="input-container">
            <label class="mb-2">Full Name</label>
            <input
              type="text"
              class="form-control"
              placeholder="Full Name"
              v-model="user.name"
              @input="checkName"
            />
            <span class="text-danger" v-if="errorFullName">*Required</span>
          </div>
          <div class="input-container">
            <label class="mb-2">Email</label>
            <input
              type="email"
              class="form-control"
              placeholder="Email"
              v-model="user.email"
              @input="checkEmail"
            />
            <span class="text-danger" v-if="errorEmail">*Required</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="input-container">
            <label class="mb-2">Market</label>
            <select
              class="form-select"
              v-model="user.market_id"
              @change="checkMarket"
            >
              <option selected disabled value="null">Market</option>
              <option
                v-for="market in markets"
                :key="market.id"
                :value="market.id"
              >
                {{ market.name }}
              </option>
            </select>
            <span class="text-danger" v-if="errorMarket">*Required</span>
          </div>
          <div class="input-container">
            <label class="mb-2">Role</label>
            <select class="form-select" v-model="user.role" @change="checkRole">
              <option selected disabled value="null">Role</option>
              <option v-for="role in roles" :key="role.id" :value="role.name">
                {{ role.name }}
              </option>
            </select>
            <span class="text-danger" v-if="errorRole">*Required</span>
          </div>
        </div>
        
            <div class="row mb-2">
          <div class="input-container">
            <label for="">Business Unit</label>
             <select class="form-select" v-model="user.bu" >
              <option selected disabled value="null">BusinessUnits</option>
              <option v-for="bu in BusinessUnits" :key="bu.id" :value="bu.name">
                {{ bu.name }}
              </option>
            </select>
            <span class="text-danger ms-2" v-if="errorBu">*Required</span>
          </div>
          <div class="input-container">
            <label for="">Cluster Team</label>
            <select class="form-select" v-model="user.cluster_team" >
              <option selected disabled value="null">cluster_team</option>
              <option v-for="ct in ClusterTeam" :key="ct.id" :value="ct.name">
                {{ ct.name }}
              </option>
            </select>
            <span class="text-danger ms-2" v-if="errorCt">*Required</span>
          </div>
        </div>
        
        <div class="row mb-2">
          <div class="input-container">
            <label class="mb-2">Password</label>
            <div class="position-relative">
              <input
                v-if="isActive1 === false"
                type="password"
                class="form-control"
                placeholder="Password"
                v-model="user.password"
                @input="checkPassword"
              />
              <input
                v-if="isActive1 === true"
                type="text"
                class="form-control"
                placeholder="Password"
                v-model="user.password"
                @input="checkPassword"
              />
              <!-- <img src="../../assets/images/show-password.png" class="show-password-img" alt="" /> -->
              <font-awesome-icon
                v-if="isActive1 === false"
                :icon="['fas', 'eye']"
                class="show-password-img"
                size="lg"
                @click="toggle1"
              />
              <font-awesome-icon
                v-if="isActive1 === true"
                :icon="['fas', 'eye-slash']"
                class="show-password-img"
                size="lg"
                @click="toggle1"
              />
            </div>
            <span class="text-danger" v-if="errorPassword">*Required</span>
          </div>
          <div class="input-container">
            <label class="mb-2">Confirm Password</label>
            <div class="position-relative">
              <input
                v-if="isActive2 === false"
                type="password"
                class="form-control"
                placeholder="Confirm Password"
                v-model="user.password_confirmation"
                @input="checkPasswordConfirmation"
              />
              <input
                v-if="isActive2 === true"
                type="text"
                class="form-control"
                placeholder="Confirm Password"
                v-model="user.password_confirmation"
                @input="checkPasswordConfirmation"
              />
              <!-- <img src="../../assets/images/show-password.png" class="show-password-img" alt="" /> -->
              <font-awesome-icon
                v-if="isActive2 === false"
                :icon="['fas', 'eye']"
                class="show-password-img"
                size="lg"
                @click="toggle2"
              />
              <font-awesome-icon
                v-if="isActive2 === true"
                :icon="['fas', 'eye-slash']"
                class="show-password-img"
                size="lg"
                @click="toggle2"
              />
            </div>
            <span class="text-danger" v-if="errorPasswordConfirmation"
              >*Required</span
            >
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<style scoped>
::placeholder {
  color: #fff !important;
}
.allowed {
  cursor: pointer;
}

.notAllowed {
  cursor: not-allowed;
}
.title {
  color: #fff;
}
.create-button {
  padding: 0.5rem 4rem;
  background-color: #f47d1f;
  font-weight: 500;
  border-radius: 25px;
  color: #ffffff;
  border: none;
}
.form-body {
  margin-top: 2rem;
  width: 100%;
  border: solid 1px #707070;
  min-height: 70vh;
  border-radius: 5px;
  /* display: flex; */
  /* flex-wrap: wrap;
        justify-content: space-around; */
  padding: 1rem;
  overflow: auto;
  color: #fff;
}
.form-body .input-container {
  width: 40%;
}
.form-body .input-container label {
  color: #707070;
  font-weight: 500;
  margin-left: 12px;
}
option {
  background-color: #fff !important;
  color: #13161c !important;
}
.form-select {
  background-color: white;
  position: relative;
  color: #3C3C3B;
  font-weight: 300;
  cursor: pointer;
  border-radius: 25px;
  border-color: #707070 !important;
  font-size: 14px;
  padding: 0.5em 1em;
}
.form-control {
  background-color: transparent;
  border: 1px solid #707070;
  border-radius: 25px;
}
</style>

<script>
import { createToaster } from "@meforma/vue-toaster";
import roleService from "../../api-services/role-service";
import manager from "../../api-services/manager";
export default {
  name: "edit-user",
  async mounted() {
    const userId = this.$route.params.id;
    this.getRoles();
    this.getMarkets();
    this.getUserData();
        this.BusinessUnits();
    this.ClusterTeam();
    this.$store.dispatch("activeTap", "users");
    await this.$store.dispatch("currentRoute", "edit-user/" + userId);
  },
  data() {
    return {
      roles: [],
      markets: [],
      user: {
        name: null,
        email: null,
        market_id: null,
        role: null,
            // bu: null,
        // cluster_team: null,
        password: null,
        password_confirmation: null,
      },
      errorFullName: false,
      errorEmail: false,
      errorMarket: false,
      errorRole: false,
      errorPassword: false,
      errorPasswordConfirmation: false,
      errorBtnSave: false,
      isActive1: false,
      isActive2: false,
    };
  },
  methods: {
    toggle1() {
      if (!this.isActive1) {
        this.isActive1 = true;
      } else {
        this.isActive1 = false;
      }
    },
    toggle2() {
      if (!this.isActive2) {
        this.isActive2 = true;
      } else {
        this.isActive2 = false;
      }
    },
    async getRoles() {
      try {
        const response = await roleService.getRoles();
        this.roles = response.data.data;
      } catch (e) {
        console.log(e);
      }
    },
    async getMarkets() {
      try {
        const response = await roleService.getMarkets();
        this.markets = response.data.data;
      } catch (e) {
        console.log(e);
      }
    },
    async getUserData() {
      const userId = this.$route.params.id;
      const response = await manager.show(userId);
      this.user = response.data.data;
    },
      async BusinessUnits() {
      try {
      
        this.BusinessUnits = [
          {id:"EE",name:"EE"},
          {id:"MD",name:"MD"},
          {id:"MA",name:"MA"}
        
        ]
      } catch (e) {
        console.log(e);
      }
    },
    async ClusterTeam() {
      try {
      
        this.ClusterTeam = [
          { id: "A", name: "A" },
          { id: "B", name: "B" },
          { id: "C", name: "C" }
        
        ]
      } catch (e) {
        console.log(e);
      }
    },
    checkErrors() {
      this.errorFullName = false;
      this.errorEmail = false;
      this.errorMarket = false;
      this.errorRole = false;
      this.errorPassword = false;
      this.errorPasswordConfirmation = false;

      if (this.user.name === null || this.user.name === "") {
        this.errorFullName = true;
      }

      if (this.user.email === null || this.user.email === "") {
        this.errorEmail = true;
      }

      if (this.user.market_id === null) {
        this.errorMarket = true;
      }
//  if (this.user.bu === null) {
//         this.errorBu = true;
//       }
//       if (this.user.cluster_team === null) {
//         this.errorCt = true;
//       }
      if (this.user.role === null) {
        this.errorRole = true;
      }

      if (this.user.password === null) {
        this.errorPassword = true;
      }

      if (this.user.password_confirmation === null) {
        this.errorPasswordConfirmation = true;
      }

      this.errorBtnSave =
        this.errorFullName ||
        this.errorEmail ||
        this.errorMarket ||
        this.errorRole ||
        //   this.errorBu ||
        // this.errorCt ||
        this.errorPassword ||
        this.errorPasswordConfirmation;
    },
    checkName() {
      this.checkErrors();
    },
    checkEmail() {
      this.checkErrors();
    },
    checkMarket() {
      this.checkErrors();
    },
    checkRole() {
      this.checkErrors();
    },
    checkBu() {
      this.checkErrors();
    },
    checkCt() {
      this.checkErrors();
    },
    checkPassword() {
      this.checkErrors();
    },
    checkPasswordConfirmation() {
      this.checkErrors();
    },
    async updateUser() {
      const regName = /^[a-zA-Z ]*$/;
      const regMail =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      try {
        const toaster = createToaster();
        const userId = this.$route.params.id;
        if (
          this.user.name === null ||
          this.user.name === "" ||
          this.user.email === null ||
          this.user.email === ""
        ) {
          this.checkErrors();
        } else if (!isNaN(this.user.name) || !regName.test(this.user.name)) {
          toaster.error(`Please enter the valid name`, { position: "top" });
        } else if (!regMail.test(this.user.email)) {
          toaster.error(`Please enter valid email`, { position: "top" });
        } else if (this.user.password !== this.user.password_confirmation) {
          toaster.error(`Password doesn't match`, { position: "top" });
        } else {
          const response = await manager.update(userId, this.user);
          if (response.status === 202) {
            toaster.success(response.data.message, { position: "top" });
            // setTimeout(() => {
            this.$router.push({ name: "users-index" });
            // }, 2000);
          } else {
            toaster.warning(`something wrong`, { position: "top" });
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
