<template>
  <!-- <Navbar :isAccount="isAccount" /> -->
  <div class="commercial-screen">
    <div class="page-header ">
      <div class="d-flex align-items-center flex-grow-1 justify-content-end gap-2">
        <button class="btn screen-btn" @click="openModal">Filter </button>
        <button class="btn screen-btn" @click.prevent="moveToHomeScreen">
          Insights
        </button>

      </div>
    </div>
    <div class="market-details">
      <div class="tab-header">
        <div class="row flex-nowrap market__row">

          <div class="col month headers">
            <h5 class="header__titles">Business Unit:</h5>
            <h6 class="details">{{ businessUnitShow.name }}</h6>
          </div>
          <div class="col market headers">
            <h5 class="header__titles">Market:</h5>
            <h6 class="details">{{ marketShow.name }}</h6>
          </div>
          <div class="col day-to-goal headers">
            <h5 class="header__titles">Days to goal:</h5>
            <h6 class="details">{{ dateGoal }} day/s</h6>
          </div>
          <div class="month headers">
            <h5 class="header__titles">Month:</h5>
            <h6 class="details">{{ monthName }}</h6>
          </div>
          <div class="col day-to-goal headers">
            <h5 class="">Date:</h5>
            <h6 class="details">{{ fullYear }}</h6>
          </div>
        </div>
      </div>

      <table class="table-container" v-if="Object.keys(clusterShow).length !== 0">
        <tr>
          <th class="table-header">Clusters</th>
          <th class="table-header">Goal</th>
          <th class="table-header">Pipeline</th>
          <th class="table-header">P.O</th>
          <th class="table-header">Invoice</th>
          <th class="table-header">Conversion</th>
          <th class="table-header">Achievement</th>
          <!-- <th class="table-header">Cluster Quantum Index</th> -->
        </tr>
        <tr v-for="(cluster, idx) in sortedClusters" :key="idx">
          <td class="table-details">
            <span>{{ cluster.name }}</span>
          </td>
          <td class="table-details">
            <span>{{ decimalNumber(cluster.goal).toLocaleString("en-US") }}</span>
          </td>
          <td class="table-details">
            <span v-if="cluster.pipline === null">0</span>
            <span v-else>{{ decimalNumber(cluster.pipline).toLocaleString("en-US") }}</span>
          </td>
          <td class="table-details">
            <span v-if="cluster.po === null">0</span>
            <span v-else>{{ decimalNumber(cluster.po).toLocaleString("en-US") }}</span>
          </td>
          <td class="table-details">
            <span v-if="cluster.invoice === null">0</span>
            <span v-else>{{ decimalNumber(cluster.invoice).toLocaleString("en-US") }}</span>
          </td>
          <td class="table-details">
            <span v-if="cluster.po_count === null || cluster.po_count === 0">0 %</span>
            <span v-else-if="cluster.pipline_count === null || cluster.pipline_count === 0">0 %</span>
            <span v-else>{{ Math.round((cluster.po / cluster.pipline) * 100) + " %" }}</span>
            {{ console.log({ "po": cluster.po }, { "potentiality": cluster.pipline }) }}

          </td>
          <td class="table-details">
            <span v-if="cluster.po === null">0 %</span>
            <span v-else-if="cluster.goal === 0">0 %</span>
            <span v-else>{{ Math.round((cluster.po / cluster.goal) * 100) + " %" ?? 0
              }}</span>
          </td>
        </tr>

        <tr>
          <td v-if="!this.cluster_team" class="table-details total__td">
            <span>Total Market Performance</span>
          </td>
          <td class="table-details total__td">
            <span>{{ decimalNumber(totalGoals).toLocaleString("en-US") }}</span>
          </td>
          <td class="table-details total__td">
            <span>{{ decimalNumber(totalPipelines).toLocaleString("en-US") }}</span>
          </td>
          <td class="table-details total__td">
            <span>{{ decimalNumber(totalPos).toLocaleString("en-US") }}</span>
          </td>
          <td class="table-details total__td">
            <span>{{ decimalNumber(totalInvoices).toLocaleString("en-US") }}</span>
          </td>
          <td class="table-details total__td">
            <span>{{
              this.totalConversion !== "NaN" ||
              this.totalConversion !== "Infinity"
              ? decimalNumber(this.totalConversion) + " %"
              : 0 + "%"
              }}</span>
          </td>
          <td class="table-details total__td">
            <span>{{
              totalAchievements !== "NaN"
              ? decimalNumber(totalAchievements) + " %"
              : 0 + " %"
              }}</span>
          </td>
        </tr>
      </table>
      <div class="no-data" v-else>
        <h3 class="text-center">No Data</h3>
      </div>
    </div>
    <div v-if="isModalOpen" class="modal-overlay">
      <div class="modal_content">
        <h2>Filter</h2>
        <div class="d-flex align-items-center flex-column  flex-grow-1 justify-content-center gap-3">
          <div class="pickers_wrapper">
            <div>
              <h6 class="details">From:</h6>
              <input type="date" v-model="startDate" @change="saveStartDateToLocalStorage"
                :min="minStartDate ? minStartDate.toISOString().split('T')[0] : ''"
                :max="new Date().toISOString().split('T')[0]" />
            </div>

            <div>
              <h6 class="details">To:</h6>
              <input type="date" v-model="endDate" @change="saveEndDateToLocalStorage"
                :min="startDate ? new Date(startDate).toISOString().split('T')[0] : ''"
                :max="new Date().toISOString().split('T')[0]" />
            </div>
          </div>
          <div class="d-flex align-items-center   flex-grow-1 justify-content-end gap-4">
            <button class="btn screen-btn" :disabled="!startDate || !endDate"
              @click.prevent="applyFilter">filter</button> <button class="btn screen-btn"
              @click="closeModal">Cancel</button>

          </div>


        </div>
      </div>
    </div>
    <footer class="text-center mt-2 footer">
      <p>
        Copyright © {{ yearBefore }}-{{ yearNow }} Caduceuslane.com. All rights
        reserved.
      </p>
    </footer>
  </div>
</template>

<style>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000000000000000;
}

.modal_content {
    width: 50%;
      height: 30vh;
      background: white;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      z-index: 10000000000000000;
      display: flex;
        flex-direction: column;
        justify-content: center;
}
.no-data {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #eca715;
  padding: 15%;
}

.commercial-screen {
  background-color: #fff;
  height: calc(100vh - 100px);
  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: hidden;
  padding: 0 2rem;
}

.commercial-screen .market-details {
  border-radius: 5px;
  min-height: 71vh;
  height: 81vh;
  padding: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.commercial-screen .tab-content {
  border: solid 1px #eca715;
  border-radius: 5px;
  min-height: 75vh;
  padding: 2rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.screen-btn {
  font-weight: 600 !important;
  padding: 0.5rem 3rem !important;
  color: #fff !important;
  background-color: #f47d1f !important;
  cursor: pointer !important;
  border-radius: 25px !important;
}

.commercial-screen .day-to-goal,
.commercial-screen .month,
.commercial-screen .market {
  width: 20% !important;
}

.commercial-screen .headers {
  display: flex;
  flex-direction: column;
}

.commercial-screen .headers h5 {
  color: #f47d1f;
  margin-right: 0.5rem;
  margin-bottom: 0;
}

.commercial-screen .details {
  color: #3c3c3b;
  font-size: 1.2rem;
  margin-bottom: 0;
  word-break: break-all;
  width: 60%;
}

.commercial-screen .sec {
  width: 15%;
  /* background-color: #ECA715; */
  height: fit-content;
  margin-right: 5px;
}

.commercial-screen .sec h5 {
  padding: 0.5rem;
  background-color: #eca715;
  border-radius: 5px;
}

.commercial-screen .sec .cluster-details {
  padding: 0.5rem;
  background-color: #4c515c9a;
  color: #fff;
  border: solid 1px #907142;
  border-radius: 5px;
  margin-bottom: 5px;
}

.commercial-screen .clusters {
  width: 15%;
}

.commercial-screen .cluster-quantum-index {
  width: 15%;
}

.commercial-screen .page-header {
  color: #fff;
  padding: 0;
}

.footer {
  color: #747474;
}

.market__row {
  border: 1px solid #dadada;
  border-radius: 17px;
  padding: 15px;
  margin-bottom: 1rem;
}

@media (width: 1535px) {
  .commercial-screen .sec .cluster-details {
    font-size: 0.75rem;
  }

  .commercial-screen .sec h5 {
    font-size: 1rem;
  }
}

@media (width: 1440px) {
  .commercial-screen .sec .cluster-details {
    font-size: 0.75rem;
  }

  .commercial-screen .sec h5 {
    font-size: 1rem;
  }
}

@media (width: 1050px) {
  .commercial-screen .sec .cluster-details {
    font-size: 0.5rem;
  }

  .commercial-screen .sec h5 {
    font-size: 0.8rem;
  }
 
}
 .pickers_wrapper {
    display: flex;
      color: #333333;
      width: 100%;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 2rem;
 }
 .pickers_wrapper >div {
   display: flex;
  color: #333333;
  align-items: center;
  gap: 1rem;
 }
 .v3dp__clearable{
    top: 3px;
      position: absolute !important;
      left: unset !important;
      cursor: pointer;
      right: 3px;
      vertical-align: middle;
      padding: 0;
      /* background: red; */
      color: red;
      border-radius: 3.7px;
      width: 1rem;
      height: 82%;

     }
     .v3dp__input_wrapper input{
      border-radius: 10px;
      border: 2px solid #797977;
      padding: .2rem;
     }
 td:nth-child(1) {
   padding: 0.5rem;
   border-left: 2px solid #dadada;
 }
</style>

<script>
import Navbar from "@/components/Navbar.vue";
import market from "../../api-services/market";
import business_unit from "../../api-services/business_unit";
import target from "../../api-services/target";
import axios from "axios";
import moment from "moment";
import store from "@/store/store";
import { ref } from 'vue'
import './custom-datepicker.css'
import Calendar from 'primevue/calendar';

export default {
  name: "commercial-screen",
  data() {
    return {
      isAccount: false,
      market: [],
      markets: [],
      businessUnits: [],
      userBu: null,
      cluster_team: null,
      businessUnitParam: null,
      marketShow: {},
      curMarketIndex: -1,
      curMarketIndexNow: 1,
      businessUnitShow: {},
      curBusinessUnitIndex: -1,
      market_id: null,
      business_unit_id: null,
      clusters: [],
      startNow: null,
      month: null,
      year: null,
      monthName: null,
      fullYear: null,
      emails: [],
      clustersReports : [],
      clusterShow: {},
      totalGoals: 0,
      totalPipelines: 0,
      totalPos: 0,
      totalInvoices: 0,
      totalConversion: 0,
      totalAchievements: 0,
      totalEnvisions: 0,
      countClusters: 0,
      dateGoal: null,
      yearNow: null,
      yearBefore: null,
      // startDate: this.getThreeMonthsFromNow(),
      // endDate: this.getOneMonthFromStartDate(this.getThreeMonthsFromNow()),
      // minStartDate:null,
      fiveYearsAgo: new Date(new Date().setFullYear(new Date().getFullYear() - 5)),
      isModalOpen: false,
      isPaused: false,  // To track if the timer is paused
      timeLeft: 20000,  // Total time for the countdown (20 seconds here, adjust as needed)
      initialTime: 20000,  // Keep the initial total time
      timerId: null, 
      pausePromise: null,
      startDate: null,
      endDate: null,
      // Ensure minStartDate is a valid Date object
      minStartDate: new Date(), // Initialize with the current date
      timeoutId: null // Store the timeout ID

    };
  },
  mounted() {
    // this.businessUnitParam = bUParam;
    let userDataLocalStorage = JSON.parse(localStorage.getItem("user"));
    this.userBu = userDataLocalStorage.user.bu
    this.cluster_team = userDataLocalStorage?.user?.cluster_team

    const d = new Date();
    this.month = d.getMonth() + 1;
    this.year = d.getFullYear();
    this.quarter = Math.floor((this.month - 1) / 3) + 1; // Calculate the quarter of the year
    let lastDayOfQuarter = new Date(d.getFullYear(), this.quarter * 3, 0); // Last day of the quarter
    let today = new Date(d.getFullYear(), d.getMonth(), d.getDate()); // Today's date
    this.dateGoal = Math.ceil(
      (lastDayOfQuarter - today) / (1000 * 60 * 60 * 24)
    ); // Days remaining in the quarter
    this.monthName = d.toLocaleString("default", { month: "long" });
    this.fullYear = moment(d).format("DD MMM YYYY");
    this.yearNow = d.getFullYear();
    this.yearBefore = d.getFullYear() - 1;
    // this.getBusinessUnits();
    this.getMarkets();
    this.endDate = ref(new Date())
    this.startDate = ref(new Date())
    this.date = ref();


  },
  created() {
    const today = new Date();
    const fiveYearsAgo = new Date(today);
    fiveYearsAgo.setFullYear(today.getFullYear() - 5);
    this.fiveYearsAgo = fiveYearsAgo;
  },
  watch: {

 
    endDate(newDate) {
      if (newDate) {
        const oneYearBefore = new Date(newDate);
        oneYearBefore.setFullYear(oneYearBefore.getFullYear() - 1);
        this.minStartDate = oneYearBefore;
      } else {
        this.minStartDate = null;
      }
    },
    startDate(newDate) {
      if (newDate && this.endDate < newDate) {
        this.endDate = newDate;
      }
    }
  },
  methods: {
    saveStartDateToLocalStorage() {
      if (this.startDate) {
        localStorage.setItem('selectedStartDate', this.startDate);
      } else {
        localStorage.removeItem('selectedStartDate');
      }
    },
    saveEndDateToLocalStorage() {
      if (this.endDate) {
        localStorage.setItem('selectedEndDate', this.endDate);
      } else {
        localStorage.removeItem('selectedEndDate');
      }
    },
    sleep(ms) {
      return new Promise((resolve) => {
        const checkPause = () => {
          if (this.isPaused) {
            this.resumePromise = new Promise((resume) => {
              this.resumeResolve = resume;  // Store reference to resolve function
            });
            // Wait until resumePromise resolves
            this.resumePromise.then(() => {
              this.isPaused = false; // Reset pause state
              checkPause(); // Check pause status again
            });
          } else {
            this.timeoutId = setTimeout(() => {
              resolve();
              this.timeoutId = null; // Clear the timeout ID after resolving
            }, ms);  // Resolve after ms if not paused
          }
        };
        checkPause();  // Start checking pause state
      });
    },
    startTimer() {
      this.resetTimer();
      this.countDown(this.timeLeft);
    },
    countDown(time) {
      if (this.isPaused || time <= 0) {
        return;  // Stop countdown if paused or time is up
      }
      this.timerId = setTimeout(() => {
        this.countDown(time - 1000); // Reduce time by 1 second and call countDown again
      }, 1000);
    },
    clearSleep() {
      if (this.timeoutId !== null) {
        clearTimeout(this.timeoutId); // Clear the timeout
        this.timeoutId = null; // Reset timeout ID
      }
    },
    pauseTimer() {
      this.isPaused = true;  // Set paused state
      clearTimeout(this.timerId);  // Clear the timeout to stop the countdown
    },
    resumeTimer() {
      this.isPaused = false;  // Reset paused state
      // Calculate remaining time and continue countdown
      const remainingTime = this.timeLeft - (this.initialTime - this.timeLeft);
      this.countDown(remainingTime);
    },
    resetTimer() {
      clearTimeout(this.timerId);
      this.isPaused = false;
      this.timeLeft = this.initialTime; // Reset time left to the initial value
    },

    async getMarkets() {
      try {
        const response = await market.indexWithoutLoading();
        this.markets = response.data.data;
        this.getBusinessUnits();
        store.dispatch("setIsLoading", true);
        localStorage.setItem("MarketsNumbers", response.data.data.length);
      } catch (e) {
        console.log(e);
      }
    },
    getOneMonthFromStartDate(startDate) {
      const date = new Date(startDate);
      date.setMonth(date.getMonth() + 1);
      return date; // Returns a Date object
    },
    openModal() {
      console.log("openModal")
      this.pauseShowBusinessUnits()

      this.isModalOpen = true;
      this.endDate = null
      this.startDate = null
    },
    closeModal() {
      this.isModalOpen = false;
      this.endDate = null
      this.startDate = null
      localStorage.setItem('selectedEndDate', "");
      localStorage.setItem('selectedStartDate', "");

      
      this.resumeShowBusinessUnits();
      window.location.reload();
      
    },
    async handleOpened() {

    },
    async handleClosed() {

    },
    getThreeMonthsFromNow() {
      const date = new Date();
      date.setMonth(date.getMonth() - 3);
      return date;
    },
formatDate(date) {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');

  return `${year}/${month}/${day}`;
},
    async applyFilter() {
      this.showPreview()
      this.isModalOpen = false;
      // console.log(this.formatDate(this.endDate))
      // console.log(this.formatDate(this.startDate))
      this.resumeShowBusinessUnits();
    },
    async getBusinessUnits() {
      try {
        const response = await business_unit.allWithoutLoader();
        let unitsToSet;
        let check = response?.data?.data.some(el => el.name === this.userBu)
        if (this.userBu && check) {
          unitsToSet = response?.data?.data.filter(unit => {
            return unit?.name === this.userBu
          });
          localStorage.setItem("businessUnitsNumbers", unitsToSet.length);
          this.businessUnits = unitsToSet;

        } else {
          this.businessUnits = response?.data?.data;

        }
        this.showPreview();
      } catch (e) {
        console.log(e);
      }
    },

    async showPreview() {

      store.dispatch("setIsLoading", false);
      if (!this.businessUnits.length) { return; }
      else {
        for (let bu of this.businessUnits) {
          await this.showBusinessUnits(bu);
        }
        this.showPreview();
      }

    },
    async showBusinessUnits(businessUnit) {
      let from = localStorage.getItem('selectedStartDate'); // Convert to ISO string
      let to = localStorage.getItem('selectedEndDate')
      this.businessUnitShow = businessUnit;
      for (let market of this.markets) {
        this.marketShow = market;

        try {
          this.clustersReports = await target.getClustersReports(market.id, businessUnit.id, from, to);
        
            this.clustersReports = this.clustersReports.data.data; // Assigning the relevant data directly to this.res
          
        } catch (error) {
          console.error("Error fetching clusters reports:", error);
          this.clustersReports = []; // Optional: Handle errors by setting res appropriately
        }
        
        if (this.clustersReports.length > 0) {
          for (let i = 0; i < this.clustersReports.length; i++) {
            this.emails.push(this.clustersReports[i].email);
          }
        } else {
          this.clusterShow = {};
        }

        let emails = Object.values(this.emails);

        if (this.emails.length > 0) {
          store.dispatch("setIsLoading", true);

          await axios.post(process.env.VUE_APP_COUES_URL + "/commericalScreen_24", {
            bu:this.businessUnitShow.name,
            from: from,
            to: to,
            email: emails,
            month: this.month,
            year: this.year,
            // month: 12,
            // year: 2024,
            market: market.id,
          }).then((response) => {
            if (response.data) {
              store.dispatch("setIsLoading", false);
              this.handleDataFormat(response.data);
            }
          });

          this.emails = [];
        }

        await this.sleep(20000);  // 20 seconds wait
      }
    },
    pauseShowBusinessUnits() {

      this.isPaused = true;  // Set paused state
    },
    resumeShowBusinessUnits() {
      this.isPaused = false;  // Reset paused state
      this.showPreview()
      this.$emit("resumeSleep");  // Emit an event to resume sleep
    },
    resetShowBusinessUnits() {
      this.isPaused = false; // Reset paused state
      this.pausePromise = null; // Clear the pause promise
      this.emails = []; // Optionally reset emails
    },
    moveToHomeScreen() {
      // this.$router.push({ name: 'sales-manager-account-page' });

      const url = `/sales-manager-account`
      window.open(url);
    },
    formatNumber(num) {
      return parseFloat(num).toFixed(2);
    },
    decimalNumber(num) {
      return parseInt(Math.round(num));
    },

    async handleDataFormat(data_24) {
      if (this.cluster_team === null || this.cluster_team === undefined) {
        // Direct assignment without filtering
        this.clusterShow = data_24;

        // here  i need to  change the value of  clustersReports 
      }
      else {
        const filteredData = Object.entries(data_24).reduce((acc, [key, value]) => {
          if (value.name === this.cluster_team) {
            acc[key] = value;
          }
          return acc;
        }, {});

        this.clusterShow = filteredData;
      }
      this.totalGoals = 0;
      this.totalPipelines = 0;
      this.totalPos = 0;
      this.totalInvoices = 0;
      this.totalConversion = 0;
      this.pipline_count = 0;
      this.po_count = 0;
      this.totalAchievements = 0;
      this.totalInvoices = 0;
      this.totalEnvisions = 0;
      let emailsEnvisions = [];
      if (this.clustersReports.length > 0) {
        this.countClusters = this.clustersReports.length;
        for (let j = 0; j < this.clustersReports.length; j++) {
          emailsEnvisions.push(this.clustersReports[j].email);
          // this.clusterShow[this.clustersReports[j].email].name =
          //   this.clustersReports[j].name;

          if (this.clustersReports[j].current_target !== null) {
            this.clusterShow[this.clustersReports[j].email].goal =
              this.clustersReports[j].current_target.target;
            this.totalGoals += +this.clustersReports[j].current_target.target;
          } else {
            this.clusterShow[this.clustersReports[j].email].goal = 0;
          }
        }
      }
      // for (let k = 0; k < emailsEnvisions.length; k++) {
      //   await axios
      //     .post(process.env.VUE_APP_CITIUS_URL + "/envision", {
      //       email: emailsEnvisions,
      //       month: this.month,
      //       year: this.year,
      //     })
      //     .then((response) => {
      //       if (response.data) {
      //         this.clusterShow[this.clustersReports[k].email].envision =
      //           response.data[k].envision;
      //       }
      //     });
      // }

      for (let i = 0; i < Object.keys(data_24).length; i++) {
        this.clusterShow[Object.keys(data_24)[i]].email = Object.keys(data_24)[i];

        if (this.clusterShow[Object.keys(data_24)[i]].pipline !== null) {
          this.totalPipelines +=
            +this.clusterShow[Object.keys(data_24)[i]].pipline;
        } else {
          this.clusterShow[Object.keys(data_24)[i]].pipline = 0;
        }

        if (this.clusterShow[Object.keys(data_24)[i]].po !== null) {
          this.totalPos += +this.clusterShow[Object.keys(data_24)[i]].po;
        } else {
          this.clusterShow[Object.keys(data_24)[i]].po = 0;
        }

        if (this.clusterShow[Object.keys(data_24)[i]].invoice !== null) {
          this.totalInvoices += +this.clusterShow[Object.keys(data_24)[i]].invoice;
        } else {
          this.clusterShow[Object.keys(data_24)[i]].invoice = 0;
        }

        this.totalEnvisions += +this.clusterShow[Object.keys(data_24)[i]].envision;
      }

      let sum = 0;
      let result = 0;
      for (let key in data_24) {
        if (data_24[key].pipline_count !== 0) {
          sum += data_24[key].po_count / data_24[key].pipline_count;
        }
      }
      result = sum / Object.keys(data_24).length;
      if (Object.keys(data_24).length == 0) {
        result = 0;
      } else {
        result = sum / Object.keys(data_24).length;
      }
      this.totalConversion = Math.round(result * 100);

      if (this.totalGoals !== 0) {
        this.totalAchievements = (this.totalPos / this.totalGoals) * 100;
      } else {
        this.totalAchievements = 0;
      }
    },
  },
  computed: {
    endDateUpperLimit() {
      // If startDate is set, return one year after startDate, otherwise, return the current date
      if (this.startDate) {
        const upperLimitDate = new Date(this.startDate);
        upperLimitDate.setFullYear(upperLimitDate.getFullYear() + 1);
        return upperLimitDate;
      }
      return new Date();  // Default to current date if startDate is not set
    },
    sortedClusters() {
      return Object.values(this.clusterShow).sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    },
  },
  components: {
    Navbar,
  },
  unmounted() {
     localStorage.clear();
     this.clearSleep()
     this.isPaused=true;
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
};
</script>
